import { useEffect, useRef } from 'react'
//import gsap from 'gsap-trial'
//import DrawSVGPlugin from 'gsap-trial/DrawSVGPlugin'
import LogoSolid from '../../../assets/images/jplogooutlined.png'
import LogoOutline from '../../../assets/images/jplogooutline.png'
import './index.scss'

const Logo = () => {
    const bgRef = useRef()
    const outlineLogoRef = useRef()
    const solidLogoRef = useRef()




  /*
    useEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin)

    gsap
      .timeline()
      .to(bgRef.current, {
        duration: 1,
        opacity: 1,
      })
      .from(outlineLogoRef.current, {
        drawSVG: 0,
        duration: 20,
      })

    gsap.fromTo(
      solidLogoRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 4,
        duration: 4,
      }
    )
  }, [])
  */
    return (
      <div className="logo-container" ref={bgRef}>
        <img
          className="solid-logo"
          ref={solidLogoRef}
          src={LogoSolid}
          alt="JavaScript,  Developer"
        />
        <img
          className="outline-logo"
          ref={outlineLogoRef}
          src={LogoOutline}
        />
      </div>
    )
  }

export default Logo