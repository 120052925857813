import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

const Contact = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const form = useRef()
    const contactArray = "Contact me".split("");

    useEffect(() => {
        let timeoutID = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 2500)

        return () => {
            clearTimeout(timeoutID)
        }
    }, [])

    const sendEmail = (e) => {
        e.preventDefault()

    emailjs
      .sendForm('service_u5g1naq', 'template_u4l0yxu', form.current, 'Vkgxoo6-GGGy4nEXY')
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send the message, please try again')
        }
      )
    }

    return (
        <> 
        <div className="container contact-page">
            <div className="text-zone">
                <h1>
                    <AnimatedLetters
                        letterClass = {letterClass}
                        strArray = {contactArray}
                        idx = {15}
                    />
                </h1>
                <p>
                    Use the menu below to send me a message!
                </p>
                <div className="contact-form">
                    <form ref = {form} onSubmit = {sendEmail}>
                        <ul>
                            <li className = "half">
                                <input type = "text" name = "from_name" placeholder = "Name" required />
                            </li>
                            <li className = "half">
                                <input type = "email" name = "email" placeholder = "Email" required />
                            </li>
                            <li>
                                <input placeholder = "Subject" type = "text" name = "subject" required />
                            </li>
                            <li>
                                <textarea placeholder = "Message" name = "message" required></textarea>
                            </li>
                            <li>
                                <input type = "submit" className = "flat-button" value = "Send" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
        <Loader type = "ball-grid-pulse" />
    </>
    )
}

export default Contact

/*
             <> 
            <div className="container contact-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters
                            letterClass = {letterClass}
                            strArray = {contactArray}
                            idx = {15}
                        />
                    </h1>
                    <p>
                        I am a computer science studnet and I want to do this and this and this.
                        I love coding and having fun and lalalal. Hit me up with opportunities!
                    </p>
                    <div className="contact-form">
                        <form ref = {form} onSubmit = {sendEmail}>
                            <ul>
                                <li className = "half">
                                    <input type = "text" name = "from_name" placeholder = "Name" required />
                                </li>
                                <li className = "half">
                                    <input type = "email" name = "email" placeholder = "Email" required />
                                </li>
                                <li>
                                    <input placeholder = "Subject" type = "text" name = "subject" required />
                                </li>
                                <li>
                                    <textarea placeholder = "Message" name = "message" required></textarea>
                                </li>
                                <li>
                                    <input type = "submit" className = "flat-button" value = "Send" />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
                <div className = "info-map">
                    JP McNerney
                    <br />
                    Lexington, KY
                    <br />
                    United States
                    <br />
                    <span>jpmcnerney1@gmail.com</span>
                </div>
                <div className = "map-wrap">
                    <MapContainer center={[38.046598, -84.497536]} zoom = {13}>
                        <TileLayer url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position = {[38.046598, -84.497536]}>
                            <Popup>"JP Lives here lol"</Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
            <Loader type = "ball-grid-pulse" />
        </>
    )
*/

