import React, { useEffect, useState } from "react";
import "./index.scss";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";
import blogData from '../../Data/blog.json';

const Blog = () => {

    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        let timeoutID = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000)

        return () => {
            clearTimeout(timeoutID);
        }
    }, []);

    const renderBlog = (blog) => {
        return (
            <div className = "images-container">
                {
                    blog.map((blogbox, idx) => {
                        return (
                            <div className = "image-box" key = {idx}>
                                <img 
                                src = {blogbox.cover}
                                className = "blog-image"
                                alt = "blog"/> 
                                <div className = "content">
                                    <p className = "date">{blogbox.date}</p>
                                    <h4 className = "title">{blogbox.title}</h4>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    
    return (

        <>
        <div className = "container blog-page">
            <h1 className = "page-title">
                <AnimatedLetters
                    letterClass = {letterClass}
                    strArray = {"Blog".split("")}
                    idx = {15}
                />
            </h1>
            <div>
                {renderBlog(blogData.blog)}
            </div>
        </div>
        <Loader type = "ball-grid-pulse"/>
        </>

    );

}

export default Blog;