import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faJava, faCss3, faGitAlt, faHtml5, faJsSquare, faReact, faSwift } from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const About = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const aboutArr = "About me".split("");

    useEffect(() => {
        let timeoutID = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 2500)

        return () => {
            clearTimeout(timeoutID)
        }
    }, [])
        
    return (
            <>
        <div className = "container about-page">
            <div className = "text-zone">
                <h1>
                    <AnimatedLetters
                        letterClass = {letterClass}
                        strArray = {aboutArr}
                        idx = {15}
                    />
                </h1>
                <p>I am a Computer Science and Mathematics student at the University of Kentucky
                    and plan to graduate in 2026. I am looking for internship opportunities
                    in order to further develop my skillset and practice what I love.
                </p>
                <h2>Languages</h2>
                <p>
                    - Swift<br></br>
                    - Java<br></br>
                    - C++<br></br>
                    - SQL <br></br>
                    - HTML / CSS / JS<br></br>
                </p>
                <h2>Frameworks</h2>
                <p>
                    - React<br></br>
                    - Node/Express<br></br>
                    - CoreData<br></br>
                </p>
                <h2>Data & Monitoring</h2>
                <p>
                    - MongoDB / Mongo Atlas<br></br>
                    - Snowflake<br></br>
                    - Firebase<br></br>
                    - Splunk<br></br>
                    - AWS CloudWatch<br></br>
                </p>
                <h2>Cloud Services</h2>
                <p>
                    - AWS (EC2, Lambda, Amplify, Route53)
                </p>
                <h2>Coursework</h2>
                <p>
                    - Data Structures & Algorithms<br></br>
                    - Systems Programming (C, Unix)<br></br>
                    - Logic / Theory of Computing<br></br>
                    - Multivariable Calculus<br></br>
                    - Differential Equations<br></br>
                    - Linear Algebra<br></br>
                    - Discrete Mathematics<br></br>
                    - Various Programming Courses (C++, Unix)
                </p>
        
                
                <p>Please visit the contact page and send me a message!</p>
            </div>

            <div className = 'stage-cube-cont'>
                <div className = "cubespinner">
                    <div className = 'face1'>
                        <FontAwesomeIcon icon = {faJava} color = "#F89820" />
                    </div>
                    <div className = 'face2'>
                        <FontAwesomeIcon icon = {faHtml5} color = "#F06529" />
                    </div>
                    <div className = 'face3'>
                        <FontAwesomeIcon icon = {faCss3} color = "#28A4D9" />
                    </div>
                    <div className = 'face4'>
                        <FontAwesomeIcon icon = {faSwift} color = "#ADD8E6" />
                    </div>
                    <div className = 'face5'>
                        <FontAwesomeIcon icon = {faJsSquare} color = "#EFD81D" />
                    </div>
                    <div className = 'face6'>
                        <FontAwesomeIcon icon = {faGitAlt} color = "#EC4D28" />
                    </div>
                </div>
            </div>

        </div>
        <Loader type = "ball-grid-pulse" />
            /</>
    )
}

export default About