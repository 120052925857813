import React, { useEffect, useState } from "react";
import "./index.scss";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";
import portfolioData from '../../Data/portfolio.json';

const Portfolio = () => {

    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        let timeoutID = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000)

        return () => {
            clearTimeout(timeoutID);
        }
    }, []);

    const renderPortfolio = (portfolio) => {
        return (
            <div className = "images-container">
                {
                    portfolio.map((port, idx) => {
                        return (
                            <div className = "image-box" key = {idx}>
                                <img 
                                src = {port.cover}
                                className = "portfolio-image"
                                alt = "portfolio"/> 
                                <div className = "content">
                                    <p className = "title">{port.title}</p>
                                    <h4 className = "skills">{port.skills}</h4>
                                    <h5 className = "description">{port.description}</h5>
                                    <button className = "button" onClick = {() => window.open(port.url)}>GO</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    
    return (

        <>
        <div className = "container portfolio-page">
            <h1 className = "page-title">
                <AnimatedLetters
                    letterClass = {letterClass}
                    strArray = {"Portfolio".split("")}
                    idx = {15}
                />
            </h1>
            <div>
                {renderPortfolio(portfolioData.portfolio)}
            </div>
        </div>
        <Loader type = "ball-grid-pulse"/>
        </>

    );

}

export default Portfolio;