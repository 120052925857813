import { Link } from 'react-router-dom';
import LogoTitle from '../../assets/images/jplogooutlined.png';
import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import { useEffect, useState } from 'react';
import Logo from './Logo';
import Loader from 'react-loaders';

const Home = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const lineOne ="JP McNerney".split("")
    const lineTwo = "Software".split("")

    useEffect(() => {
        let timeoutID = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 5000)

        return () => {
            clearTimeout(timeoutID)
        }
    }, [])

    return (

        <>
        <div className = "container home-page">
            <div className = "text-zone">
                <h1>
                <AnimatedLetters letterClass = {letterClass}
                strArray = {lineOne}
                idx = {10} />
                <br />
                <AnimatedLetters letterClass = {letterClass} 
                strArray = {lineTwo}
                idx = {28} />
                </h1>
                <h2>Swift | Java | C++ | SQL | HTML | CSS | JS</h2>
          
                <h2></h2>
                <Link to = "/portfolio" className = 'flat-button'>MY PROJECTS</Link>
                <Link to = "/contact" className = 'flat-button'>CONTACT ME</Link>
            </div>
            <Logo />
        </div>
        <Loader type = "ball-grid-pulse" />
        </>

    ); 

}

export default Home