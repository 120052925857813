import { Link, NavLink } from 'react-router-dom'
import './index.scss'
import Logo from '../../assets/images/jplogooutlined.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faHome, faUser, faSuitcase, faBars, faClose, faComment } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { useState } from 'react'

const Sidebar = () => {

    const [showNav, setShowNav] = useState(false);

    return (
    
    <div className = "nav-bar">
        <Link className = "logo" to = "/">
            <img src={Logo} alt = "logo" />
        </Link>
        <nav className = {showNav ? 'mobile-show' : ''}>
            <NavLink onClick = {() => setShowNav(false)} exact = "true" activeclassname = "active" to = "/">
                <FontAwesomeIcon icon = {faHome} color = "#4d4d4e" />
            </NavLink>
            <NavLink onClick = {() => setShowNav(false)} exact = "true" activeclassname = "active" className = "about-link" to = "/about">
                <FontAwesomeIcon icon = {faUser} color = "#4d4d4e" />
            </NavLink>
            <NavLink onClick = {() => setShowNav(false)} exact = "true" activeclassname = "active" className = "contact-link" to = "/contact">
                <FontAwesomeIcon icon = {faEnvelope} color = "#4d4d4e" />
            </NavLink>
            <NavLink onClick = {() => setShowNav(false)} exact = "true" activeclassname = "active" className = "portfolio-link" to = "/portfolio">
                <FontAwesomeIcon icon = {faSuitcase} color = "#4d4d4e" />
            </NavLink>
            <NavLink onClick = {() => setShowNav(false)} target = "_blank" rel = 'noreferrer' exact = "true" activeclassname = "active" className = "linkedin-link" to = "https://www.linkedin.com/in/jp-mcnerney-1227ab251/">
                <FontAwesomeIcon icon = {faLinkedin} color = "#4d4d4e" />
            </NavLink>
            <NavLink onClick = {() => setShowNav(false)} exact = "true" activeclassname = "active" className = "blog-link" to = "/blog">
                <FontAwesomeIcon icon = {faComment} color = "#4d4d4e" />
            </NavLink>
            <FontAwesomeIcon
                onClick = {() => setShowNav(false)}
                icon = {faClose}
                color = "#00c6ff"
                size = "3x"
                className = "close-icon"
            />
        </nav>
        <ul>
            <li>
                {/* <a target = "_blank" rel = 'noreferrer' href = 'https://www.linkedin.com/in/jp-mcnerney-1227ab251/'>
                    <FontAwesomeIcon icon = {faLinkedin} color = "#4d4d4e" />
                </a> */}
            </li>
        </ul>
        <FontAwesomeIcon
            onClick = {() => setShowNav(true)}
            icon = {faBars}
            color = "#00c6ff"
            size = "3x"
            className = "hamburger-icon"
            />
    </div>
    )
}

export default Sidebar